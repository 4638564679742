/* Show/hide machine generated versions. The versions are initially hidden.
 *
 * It expects a toggle named toggleMachineVersions and any number of elements
 * with the class .js-version-machinemade
 */

(function () {
  let toggle = document.querySelector("#toggleMachineVersions");
  if (toggle) {
    let hidden = toggle.checked;

    function hideShow() {
      hidden = !hidden;
      let machineMade = document.querySelectorAll(".js-version-machinemade");
      if (hidden) {
        machineMade.forEach((version) => {
          version.setAttribute("hidden", "hidden");
        });
      } else {
        machineMade.forEach((version) => {
          version.removeAttribute("hidden");
        });
      }
    }

    toggle.addEventListener("click", hideShow);
    hideShow();
  }
})();
