function handleDeleteClick(button) {
  const target = document.getElementById(button.getAttribute("aria-controls"));
  if (!target) {
    return;
  }
  if (matchMedia("(prefers-reduced-motion)").matches) {
    target.remove();
  } else {
    target.addEventListener("transitionend", (e) => {
      target.remove();
    });
    target.classList.add("is-deleting");
  }
}

function handleAddClick(template, table) {
  const newRow = template.content.cloneNode(true).querySelector("tr");
  const tableBody = table.tBodies[0];
  updateIndexes(newRow, findNextIndex(tableBody));
  tableBody.appendChild(newRow);
}

function findNextIndex(tBody) {
  const largestExistingIndex = [].reduce.call(
    tBody.rows,
    (currentMax, currentRow) => {
      const positionInput = currentRow.querySelector("input[type=hidden]");
      if (!positionInput) {
        return currentMax;
      }
      return Math.max(currentMax, positionInput.value);
    },
    -1,
  );
  return Math.max(largestExistingIndex + 1, tBody.rows.length);
}

function updateIndexes(row, newIndex) {
  const newRowId = row.id.replace("INDEX", newIndex);
  row.id = newRowId;
  [].forEach.call(row.querySelectorAll("input[name]"), (input) => {
    input.name = input.name.replace("INDEX", newIndex);
  });
  const hiddenInput = row.querySelector("input[type=hidden]");
  hiddenInput.value = hiddenInput.value.replace("INDEX", newIndex);
  const deleteButton = row.querySelector(".js-tabular-fields-delete");
  deleteButton.setAttribute("aria-controls", newRowId);
}

const containers = document.querySelectorAll(".js-tabular-fields");
[].forEach.call(containers, (container) => {
  const table = container.querySelector("table");
  const template = container.querySelector("template");
  /* This just catches bubbling events in order to detect clicks on delete
   * buttons in rows that have been added after document load. Alternatively,
   * we could add one event listener per delete button instead, including the
   * buttons that are added in handleAddClick
   */
  container.addEventListener("click", (e) => {
    const deleteButton = e.target.closest(".js-tabular-fields-delete");
    if (deleteButton) {
      handleDeleteClick(deleteButton);
      return;
    }
    const addButton = e.target.closest(".js-tabular-fields-add");
    if (addButton) {
      handleAddClick(template, table);
    }
  });
});
