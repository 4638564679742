/* Tracks events for Google Analytics.
 *
 * When a page is printed the 'afterprint' event listener is triggered
 * and pushes a 'Print Intent' event to dataLayer.
 *
 * When something is copied on a page the 'copy' event listener is triggered
 * and pushes a 'Copy Intent' event to dataLayer.
 *
 * When someone uses the dictionary lookup functionality on Lex.dk the 'dictionary
 * event' listener is triggered and pushes a 'Den Danske Ordbog' event to dataLayer.
 *
 * When someone interacts with the search bar on a page for the first time the
 * 'search bar interaction' event listener is triggered and pushes a 'search_start'
 * event to dataLayer.
 *
 * When someone clicks a Table of Contents item, an internal link, or an article contribution link
 * a click event is pushed to dataLayer.
 */

if (typeof dataLayer !== "undefined") {
  window.addEventListener("afterprint", function () {
    dataLayer.push({ event: "Print Intent" });
  });

  window.addEventListener("copy", function () {
    dataLayer.push({ event: "Copy Intent" });
  });

  window.addEventListener("dictionary event", function () {
    dataLayer.push({ event: "Den Danske Ordbog" });
  });

  window.addEventListener("search bar interaction", function () {
    dataLayer.push({ event: "search_start" });
  });

  var tocItems = document.getElementsByClassName("toc__link");
  [].forEach.call(tocItems, function (tocItem) {
    tocItem.addEventListener("click", function () {
      dataLayer.push({
        event: "click",
        type: "anchor_link",
        link_text: tocItem.innerText,
      });
    });
  });

  var deepLinks = document.getElementsByClassName("crossref");
  [].forEach.call(deepLinks, function (deepLink) {
    deepLink.addEventListener("click", function () {
      dataLayer.push({
        event: "click",
        type: "deep_link",
        link_text: deepLink.innerText,
      });
    });
  });

  var contributionLinks = document.getElementsByClassName(
    "article-contributions__item",
  );
  [].forEach.call(contributionLinks, function (contributionLink) {
    contributionLink.addEventListener("click", function () {
      dataLayer.push({
        event: "click",
        type: "contribution_link",
        link_text: contributionLink.innerText,
      });
    });
  });
}
