/* Fills in the current year in the indicated elements.
 *
 * To use, give an empty element the class "js-current-year". The element's
 * contents will be set to a text node containing the current year; e.g. "2020"
 */
(function () {
  var elements = document.querySelectorAll(".js-current-year");
  var year = new Date().getFullYear();
  for (var i = 0; i < elements.length; i++) {
    elements[i].textContent = year;
  }
})();
