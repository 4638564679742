/* Fills in the current date in the indicated elements.
 *
 * To use, give an empty element the class "js-current-date". The elements
 * contents will be set to a text node containing the current date in the
 * format "d. mmmm yyyy" in the document's language; e.g. "3. juli 2018"
 */
(function () {
  var elements = document.querySelectorAll(".js-current-date");
  var dateString = new Date().toLocaleString(document.documentElement.lang, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  for (var i = 0; i < elements.length; i++) {
    elements[i].textContent = dateString;
  }
})();
