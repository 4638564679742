/* Sets up toggling (show/hide) of the reply form of a comment thread.
 * The form will be hidden initially.
 *
 * An element wrapping the contents of the comment thread must have class
 * 'js-comment-thread' and 'data-reply-form' attribute set to the ID of the
 * form to be toggled. Each toggle button must have class 'js-reply-toggle'.
 *
 * The class "is-showing" is added to the _parent_ of each toggle button when
 * the form is showing, and is removed when the form is hidden.
 *
 * The form is hidden by toggling the 'hidden' attribute. Care should be taken
 * to avoid unintentionally overriding the effect by overriding the 'display'
 * property. In addition to this attribute, some ARIA attributes will also be
 * set on the form and the toggle buttons.
 *
 * The toggle buttons may have a 'hidden' attribute, which will be removed at
 * setup (so that the button can be hidden when the script isn't functional,
 * e.g. if JS is disabled).
 */
(function () {
  var threads = document.querySelectorAll(".js-comment-thread");
  [].forEach.call(threads, function (thread) {
    var formId = thread.getAttribute("data-reply-form");
    var form = document.getElementById(formId);
    if (!form) {
      return;
    }
    var triggers = thread.querySelectorAll(".js-reply-toggle");
    var formShouldShow = false;

    function updateFormState() {
      if (formShouldShow) {
        form.removeAttribute("hidden");
      } else {
        form.setAttribute("hidden", "");
      }
      form.setAttribute("aria-hidden", formShouldShow ? "false" : "true");
      [].forEach.call(triggers, function (trigger) {
        trigger.setAttribute(
          "aria-expanded",
          formShouldShow ? "true" : "false",
        );
        trigger.parentNode.classList[formShouldShow ? "add" : "remove"](
          "is-showing",
        );
      });
      if (formShouldShow) {
        form.removeAttribute("hidden");
      }
    }

    function handleToggle() {
      formShouldShow = !formShouldShow;
      updateFormState();
    }

    [].forEach.call(triggers, function (trigger) {
      trigger.setAttribute("aria-controls", formId);
      trigger.removeAttribute("hidden");
      trigger.addEventListener("click", handleToggle);
    });
    updateFormState();
  });
})();
