/* Sets up autocomplete/search suggestions for a search field.
 * Usage: give the search field the class 'js-autocomplete' and the following
 * attributes:
 * - data-autocomplete-base-url
 * - data-autocomplete-base-class
 * - data-autocomplete-output (optional)
 *
 * This is sufficient to enable autocomplete on an element that exists on the
 * page already (via static HTML). To enable autocomplete on elements that are
 * dynamically added later on, import this script as a function and call it
 * when the element has been added, passing the element as a parameter. E.g.:
 *
 * import setupAutocomplete from 'javascripts/autocomplete';
 * […]
 * setupAutocomplete(myInputField);
 *
 * The value of data-autocomplete-base-url will be used to fetch autocomplete
 * data (for each change in the search field input) by appending the search
 * term and sending a GET request. The result must be a JSON array where each
 * entry is an object including the fields
 * - 'title' (string)
 * - 'encyclopedia_name' (string or null/undefined)
 * - 'excerpt' (string or null/undefined, markup of HTML phrasing content)
 * - 'article_url' (string or null/undefined)
 * - 'id' (number or null/undefined)
 *
 * If data-autocomplete-output is given, it should contain the ID of a hidden
 * input element. In that case, the 'id' in the search results is not optional.
 * When the user selects an autocomplete result, the chosen entry's 'id' will
 * be set as value of the hidden input.
 *
 * If data-autocomplete-output is not given, the 'article_url' in the search
 * results is not optional. When the user selects an autocomplete result, the
 * chosen entry's URL will be opened.
 *
 * The field will be wrapped in a div with class 'awesomplete' which will also
 * contain a UL of autocomplete results and a .visually-hidden SPAN with hints
 * for screen reader users.
 *
 * Elements within the UL have classes that are generated based on the value of
 * data-autocomplete-base-class. E.g. if it is set to 'search-form', then each
 * LI in the UL will have class search-form__autocomplete-item and contain
 * these elements:
 * - .search-form__autocomplete-title
 * - .search-form__autocomplete-encyclopedia (if applicable)
 * - .search-form__autocomplete-excerpt (if applicable)
 */
function getAutocompleteItemBuilder(baseClass) {
  return function createAutocompleteItem(suggestion) {
    var title = suggestion.value.title;
    var excerpt = suggestion.value.excerpt;
    var encyclopedia = suggestion.value.encyclopedia;

    var li = document.createElement("li");
    li.className = `${baseClass}__autocomplete-item`;

    var titleElement = document.createElement("strong");
    titleElement.className = `${baseClass}__autocomplete-title`;
    titleElement.textContent = title;
    li.appendChild(titleElement);

    if (encyclopedia) {
      var encyclopediaElement = document.createElement("span");
      encyclopediaElement.className = `${baseClass}__autocomplete-encyclopedia`;
      encyclopediaElement.textContent = " (" + encyclopedia + ")";
      li.appendChild(encyclopediaElement);
    }

    if (excerpt) {
      var excerptElement = document.createElement("p");
      excerptElement.className = `${baseClass}__autocomplete-excerpt`;
      excerptElement.innerHTML = excerpt;
      li.appendChild(excerptElement);
    }

    return li;
  };
}

function inputListener(searchField, awesomplete) {
  if (!searchField.value) {
    awesomplete.list = [];
    return;
  }

  var baseUrl = searchField.dataset.autocompleteBaseUrl;
  var ajax = new XMLHttpRequest();
  ajax.open("GET", baseUrl + encodeURIComponent(searchField.value));
  ajax.onload = function () {
    try {
      var list = JSON.parse(ajax.responseText);
      awesomplete.list = list;
    } catch (e) {
      console.log(e);
      awesomplete.list = [];
    }
  };
  ajax.send();
}

function selectHandler(e, awesomplete, input, output) {
  e.preventDefault();
  var suggestion = e.text;
  if (output) {
    output.value = suggestion.value.id;
    let textPreview = suggestion.value.title;
    if (suggestion.value.encyclopedia) {
      textPreview += ` (${suggestion.value.encyclopedia})`;
    }
    input.value = textPreview;
    awesomplete.close();
  } else {
    window.location = suggestion.value.article_url;
  }
}

function debounceInput(func, wait, inputEl) {
  var DEBOUNCE_INPUT_MIN_LENGTH = 10;
  var timeout;

  return function () {
    var args = arguments;
    var invokeDebouncedFunction = () => {
      func.apply(this, args);
    };

    clearTimeout(timeout);
    if (inputEl.value.length < DEBOUNCE_INPUT_MIN_LENGTH) {
      invokeDebouncedFunction();
      return;
    }

    timeout = setTimeout(invokeDebouncedFunction, wait);
  };
}

var awesompleteBaseOptions = {
  minChars: 1,
  filter: function () {
    return true;
  },
  sort: false,
  data: function (item) {
    return {
      label: item.title,
      value: item,
    };
  },
  translations: {
    promptPositive: window.Strings.autocompletePromptPositive,
    promptZero: window.Strings.autocompletePromptZero,
    resultsFound: function (number) {
      return number + window.Strings.autocompleteResultsFound;
    },
    resultItem: window.Strings.autocompleteResultItem,
  },
};

function setup(searchField) {
  const baseClass = searchField.dataset.autocompleteBaseClass;
  const awesompleteOptions = {
    ...awesompleteBaseOptions,
    item: getAutocompleteItemBuilder(baseClass),
  };
  const awesomplete = new window.Awesomplete(searchField, awesompleteOptions);
  const onInput = debounceInput(
    function () {
      inputListener(searchField, awesomplete);
    },
    250,
    searchField,
  );
  const output =
    searchField.dataset.autocompleteOutput &&
    document.getElementById(searchField.dataset.autocompleteOutput);
  searchField.addEventListener("input", onInput);
  let firstInteraction = true;
  searchField.addEventListener("input", function () {
    if (firstInteraction) {
      document.dispatchEvent(
        new Event("search bar interaction", { bubbles: true }),
      );
      firstInteraction = false;
    }
  });
  searchField.addEventListener("awesomplete-select", (e) =>
    selectHandler(e, awesomplete, searchField, output),
  );
  awesomplete.list = [];
}

for (const searchField of document.querySelectorAll(".js-autocomplete")) {
  setup(searchField);
}

export default setup;
