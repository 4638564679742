/* Shows the login button only if the user has logged in before.
 * Usage:
 * - Set hidden attribute on the login button
 * - Set class js-login-button on the login button
 * - Make sure localStorage has the 'hasLoggedIn' item set to 'true' iff the
 *   user has logged in before
 * (As for omitting the login button when user is already logged in, this is
 * assumed to be done elsewhere, server-side.)
 */
(function () {
  var loginButton = document.querySelector(".js-login-button");
  if (
    loginButton &&
    window.localStorage &&
    window.localStorage.getItem("hasLoggedIn") === "true"
  ) {
    loginButton.hidden = false;
  }
})();
